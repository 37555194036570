<template>
  <v-container>
    <div>
      <v-card
        v-if="documentData"
        class="pa-2"
      >
        <v-card-title class="subheading font-weight-bold info--text">
          <span>{{ documentData.title }}</span>
          <v-spacer></v-spacer>
          <group-documents-menu
            v-if="isOwner"
            @clickEdit="isShow.edit=true"
            @clickDelete="isShow.delete=true"
          />
        </v-card-title>
        <v-card-subtitle
          class="d-flex"
        >
          <span>{{ documentData.description }}</span>
          <v-spacer />
          <v-btn
            class="mt-3"
            link
            :to="{name:'groupDocuments'}"
          >
            TORNA ALLA LISTA DOCUMENTI
          </v-btn>
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <div
            class="d-flex justify-end"
          >
            <v-btn
              v-if="isOwner"
              :color="btnColor"
              :dark="btnDark"
              @click="isShow.upload = true"
            >
              <v-icon>
                mdi-upload
              </v-icon>
              CARICA FILE
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="documentData.files_count > 0"
              :color="btnColor"
              :dark="btnDark"
              :loading="loadingAll"
              @click="downloadAllWithAxios(documentData.title+'.zip')"
            >
              <v-icon>
                mdi-download
              </v-icon>
              Scarica intero documento
            </v-btn>
          </div>
        </v-card-text>
        <v-row
          v-if="documentData.files_count > 0"
        >
          <v-col
            v-for="(file,idx) in documentData.files"
            :key="idx"
            cols="2"
          >
            <file-card
              no-thumb
              is-plugin-version
              :procedure-id="1"
              :file-obj="file"
              :download-url="downloadUrl(file.id)"
              :upload-enabled="uploadEnabled(documentData)"
              @reloadData="reloadData"
            />
          </v-col>
        </v-row>
      </v-card>

      <upload-file
        is-plugin-version
        :is-show="isShow.upload"
        :upload-url="uploadUrl"
        @close="isShow.upload=false"
        @reload-data="reloadData"
      />
      <edit-group-document
        is-plugin-version
        no-auto-import
        :is-show="isShow.edit"
        :document-data="documentData"
        @close="isShow.edit=false"
        @reload-data="reloadData"
      />
      <destroy-group-document
        :is-show="isShow.delete"
        :document-data="documentData"
        is-plugin-version
        @close="isShow.delete=false"
        @reload-data="reloadData"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import GroupDocumentsMenu from '@/components/menus/group-documents-menu.vue'
import FileCard from '@/components/cards/file-card.vue'
import UploadFile from '@/components/modals/upload-file.vue'
import EditGroupDocument from '@/components/modals/edit-group-document.vue'
import DestroyGroupDocument from '@/components/modals/delete-group-document.vue'

export default {
  name: 'GroupDocumentFiles',
  components: {
    DestroyGroupDocument,
    EditGroupDocument,
    UploadFile,
    FileCard,
    GroupDocumentsMenu,
  },
  data() {
    return {
      isShow: {
        edit: false,
        delete: false,
        upload: false,
      },
      documentData: {},
      loading: { 0: false },
      loadingAll: false,
      uploadUrl: '',
    }
  },
  computed: {
    ...mapGetters(['token', 'loggedUser', 'loggedUserGroup', 'groupDocumentsList', 'partnerSettings']),
    btnColor() {
      return this.partnerSettings?.colors?.btn ?? 'primary'
    },
    btnDark() {
      return this.partnerSettings?.colors?.btnDark ?? false
    },
    isOwner() {
      return this.loggedUser.company_role === 'owner'
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      const documentId = parseInt(this.$route.params.did, 10)
      this.documentData = await this.groupDocumentsList.find(item => item.id === documentId)

      this.uploadUrl = `${this.$apiUrl}ext/group-documents/${this.loggedUserGroup.id}/documents/${documentId}`
    },
    async reloadData() {
      await this.$store.dispatch('getGroupDocuments', this.loggedUserGroup.id)
      await this.getData()
    },
    forceFileDownload(response, title) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', title)
      document.body.appendChild(link)
      link.click()
    },
    downloadWithAxios(fid, title, idx) {
      const url = `${this.$apiUrl}ext/group-documents/${this.loggedUserGroup.id}/documents/${this.$route.params.did}/files/${fid}`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.setLoading(idx)
        })
        .catch(() => console.log('error occured'))
    },
    downloadAllWithAxios(title) {
      this.loadingAll = true
      const url = `${this.$apiUrl}ext/group-documents/${this.loggedUserGroup.id}/documents/${this.$route.params.did}/download/all`
      axios({
        method: 'get',
        url,
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then(response => {
          this.forceFileDownload(response, title)
          this.loadingAll = false
        })
        .catch(() => {
          console.log('error occured')
          this.loadingAll = false
        })
    },
    setLoading(idx) {
      if (this.loading[idx] === undefined) {
        this.$set(this.loading, idx, true)
      } else {
        this.loading[idx] = !this.loading[idx]
      }
    },
    formatWeight(size) {
      let finalSize = size / 1000

      // mega
      if (finalSize > 1000) {
        finalSize /= 1000

        return `${finalSize.toFixed(2)} MB`
      }

      return `${finalSize.toFixed(0)} KB`
    },
    uploadEnabled(doc) {
      return true
    },
    downloadUrl(fileId) {
      return `${this.$apiUrl}ext/group-documents/${this.loggedUserGroup.id}/documents/${this.$route.params.did}/files/${fileId}`
    },
  },
}
</script>

<style scoped>

</style>
